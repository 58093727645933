import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import locale from "../../locale/en"
import {
  CalculatorLayout,
  CalculatorInput,
  CalculatorTotal,
  CalculatorButtons,
  CalculatorResultsBox,
  CalculatorGuidelines,
  CalculatorTimePeriodSelect,
} from "../../components/calculator"
import { sections as faqSections } from "../../components/faq-content/face-masks"
import Fieldset from "../../components/fieldset"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import logger from "../../lib/logger"
import { generateOnSubmit } from "../../lib/calculator-sections"
import { DAY, WEEK } from "../../lib/multipliers"
const { calculator, faq } = locale
const log = logger.extend("calculator:face-masks")

const SECTION_KEY = "face_masks"

const RESET_KEYS = [
  "cloth_masks_per_employee",
  "disposable_masks_per_employee",
  "x95_masks_per_employee",
]

const INTERVAL_OPTIONS = [
  {
    value: DAY,
    label: "Daily",
  },
  {
    value: WEEK,
    label: "Weekly",
  },
]

const CalculatorMasks = () => {
  const { register, errors, handleSubmit } = useForm()
  const onSubmit = generateOnSubmit(SECTION_KEY)
  const hasErrors = !!Object.keys(errors).length

  if (hasErrors) {
    log("Form errors:", errors)
  }

  return (
    <Layout>
      <SEO title={`${calculator.title} - ${faq.face_masks}`} />
      <CalculatorLayout sectionKey={SECTION_KEY}>
        <h2 className="text-black mb-5">{faq.face_masks}</h2>

        <CalculatorResultsBox>
          <div>
            <CalculatorTotal totalKey="cloth_masks_per_employee_total" />
            <div className="text-sm">Cloth masks</div>
          </div>
          <div>
            <CalculatorTotal totalKey="disposable_masks_per_employee_total" />
            <div className="text-sm">Disposable surgical masks</div>
          </div>
          <div>
            <CalculatorTotal totalKey="x95_masks_per_employee_total" />
            <div className="text-sm">KN95 and N95 respirators</div>
          </div>
        </CalculatorResultsBox>

        <form className="container mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 mb-5 justify-between">
            <div className="w-1/2">
              <h3 className="text-black mb-4">Inputs</h3>

              <fieldset className="mb-5">
                My business needs, per employee:
              </fieldset>

              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="cloth_masks_per_employee"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="cloth_masks_per_employee"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="cloth_masks_per_employee">cloth masks</label>{" "}
                <Fieldset
                  errorMessage={
                    <ErrorMessage
                      errors={errors}
                      name="face_masks_replace_cloth_masks_in_weeks"
                    />
                  }
                  inline={true}
                  className="ml-1"
                >
                  <div>
                    per{" "}
                    <CalculatorInput
                      type="number"
                      name="face_masks_replace_cloth_masks_in_weeks"
                      className="w-16 mr-1"
                      register={register({
                        min: {
                          value: 0,
                          message: "Must be 0 or greater",
                        },
                      })}
                    />{" "}
                    weeks
                  </div>
                </Fieldset>
              </Fieldset>

              <fieldset className="mb-5">
                On a{" "}
                <CalculatorTimePeriodSelect
                  className="inline-block"
                  name="face_masks_time_period"
                  options={INTERVAL_OPTIONS}
                />{" "}
                basis, my business needs, per employee:
              </fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="disposable_masks_per_employee"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="disposable_masks_per_employee"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label
                  htmlFor="disposable_masks_per_employee"
                  className="flex-grow"
                >
                  disposable surgical masks
                </label>
              </Fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage errors={errors} name="x95_masks_per_employee" />
                }
              >
                <CalculatorInput
                  type="number"
                  name="x95_masks_per_employee"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="x95_masks_per_employee">
                  KN95 and N95 respirators
                </label>
              </Fieldset>

              <p className="mb-5">
                On a weekly basis, for all my customers, my business needs:
              </p>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="disposable_masks_for_all_customers"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="disposable_masks_for_all_customers"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="disposable_masks_for_all_customers">
                  disposable surgical masks
                </label>
              </Fieldset>

              <CalculatorButtons
                register={register}
                sectionKey={SECTION_KEY}
                resetKeys={RESET_KEYS}
              />
            </div>

            <CalculatorGuidelines sections={faqSections} />
          </div>
        </form>
      </CalculatorLayout>
    </Layout>
  )
}

export default CalculatorMasks
