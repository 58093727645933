import React from "react"
import Content from "./content"
import { OutboundLink } from "gatsby-plugin-gtag"

export const sections = [
  {
    heading: "What are standards for face coverings?",
    body: (
      <>
        <p>
          As per{" "}
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/community/organizations/meat-poultry-processing-workers-employers.html">
            CDC guidance
          </OutboundLink>
          , while there is no single standard for non-medical face coverings,
          they should:
        </p>
        <ul>
          <li>
            fit over the nose and mouth and fit snugly but comfortably against
            the face;
          </li>

          <li>be secured with ties or ear loops</li>

          <li>include multiple layers of fabric, if made of cloth</li>

          <li>allow for breathing without restriction</li>

          <li>
            if not disposable, be able to be laundered using hot water in a
            washing machine after each full day of use without damage or change
            to shape
          </li>

          <li>
            not include any one-way valves in order to keep respiratory droplets
            from reaching others
          </li>
        </ul>
      </>
    ),
  },
  {
    heading: "When should you use a cloth vs. disposable surgical mask?",
    body: (
      <>
        <p>
          Cloth masks and disposable surgical masks provide only a small degree
          of protection against COVID-19, but as the CDC notes,{" "}
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-guidance.html#evidence-effectiveness">
            they are important for preventing transmission
          </OutboundLink>{" "}
          and are the right choice for most businesses.
        </p>
        <p>
          As long as sufficient disposable surgical masks are available for
          medical use, the choice between a cloth mask and a surgical mask is up
          to an individual business or employee.{" "}
          <em>You may wish to use both in your workplace</em> – for example,
          each employee could be provided with 5 cloth face masks, and an
          additional supply of surgical masks could be available on site in the
          event that an employee forgets their reusable mask, their mask becomes
          soiled, or a customer needs a mask.
        </p>
      </>
    ),
  },
  {
    heading: "How many cloth masks should I give each employee?",
    body: (
      <>
        <p>
          Reusable face coverings should be laundered after each use, while
          disposable surgical masks should be thrown away after each use.
        </p>
        <p>
          Let’s assume employees are not able to wash masks more than once a
          week. Thus, an employee needs at least one mask per shift that week
          (which might be more if masks get spoiled during a shift!). Therefore,
          if an employee is working one shift each weekday, then that employee
          should be provided with at least five reusable cloth masks, or five
          new disposable masks per week.
        </p>
        <p>
          If work activity is likely to cause the covering to become wet,
          soiled, or otherwise contaminated during the work shift, as per{" "}
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/community/organizations/meat-poultry-processing-workers-employers.html">
            CDC guidance
          </OutboundLink>
          , employers should provide clean replacement masks.
        </p>
      </>
    ),
  },
  {
    heading: "When should you use a N95 or KN95 respirators?",
    body: (
      <>
        <p>
          N95 and KN95 respirator masks provide more protection than cloth masks
          or surgical masks, but worldwide supply is limited and they are
          currently only recommended for use by healthcare personnel and others
          who need protection from both airborne and fluid hazards (e.g.,{" "}
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/community/organizations/meat-poultry-processing-workers-employers.html">
            splashes and sprays in meat and poultry processing facilities
          </OutboundLink>
          ). If used, respirators should be fit tested and may also require the
          implementation of a respiratory program under OSHA.
        </p>
      </>
    ),
  },
]

const FaceMasksContent = () => <Content sections={sections} />

export default FaceMasksContent
